import React, { Suspense, createContext, useState, useCallback, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { auth, db, getMediaUrl } from './config';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, onSnapshot } from 'firebase/firestore';


// const AuthedApp = React.lazy(() => import("./AuthedApp"));
const Public = React.lazy(() => import("./apps/public"));
const Organisation = React.lazy(() => import("./apps/organisation"));
const Champion = React.lazy(() => import("./apps/champion"));
const Auth = React.lazy(() => import("./apps/auth"));
const Volunteer = React.lazy(() => import("./apps/volunteer"));


export const AppContext = createContext({
    appState: {},
    setAppState: () => { }
});

const CONTEXT_CACHE_KEY = 'AppContext';

export default function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const [appState, setAppState] = useState(useCallback(() => {
        let context = {};
        let cache = localStorage.getItem(CONTEXT_CACHE_KEY);
        try {
            if (cache) context = JSON.parse(cache);
            else context = {};
        } catch (error) {
            console.error(error);
        }
        return context;
    }, []));

    // useEffect(() => {
    //     let snapshotUnsubscribe;
    //     const authUnsubscribe = onAuthStateChanged(auth, async (user) => {
    //         snapshotUnsubscribe?.();

    //         if (user) {
    //             snapshotUnsubscribe = onSnapshot(doc(db, 'users', user.uid), docSnap => {
    //                 if (!docSnap.exists() || !docSnap.data()?.firstName) {
    //                     navigate(`/auth/account`, { replace: true });
    //                 } else {
    //                     setAppState(appState => ({
    //                         ...appState,
    //                         user: {
    //                             id: docSnap.id,
    //                             image: getMediaUrl(`users/${docSnap.id}.jpg`),
    //                             ...docSnap.data()
    //                         }
    //                     }));
    //                 }
    //             });
    //         }
    //     });

    //     return () => { authUnsubscribe?.(); snapshotUnsubscribe?.() };
    // }, []);

    const providerState = {
        appState,
        setAppState
    }

    useEffect(() => {
        localStorage.setItem(CONTEXT_CACHE_KEY, JSON.stringify(appState));
    }, [appState]);

    return (
        <AppContext.Provider value={providerState}>
            <div className="min-h-full bg-gray-50 text-gray-800">
                <Routes>
                    <Route path="/auth/*" element={<Suspense fallback={<></>}><Auth /></Suspense>} />
                    <Route path="/organisation/*" element={<Suspense fallback={<></>}><Organisation /></Suspense>} />
                    <Route path="/champion/*" element={<Suspense fallback={<></>}><Champion /></Suspense>} />
                    <Route path="/volunteer/*" element={<Suspense fallback={<></>}><Volunteer /></Suspense>} />
                    <Route path="*" element={<Suspense fallback={<></>}><Public /></Suspense>} />
                </Routes>
            </div>
        </AppContext.Provider >
    )
}