import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBvkhP36FcdWGMSU2GCbr1J8LaL8s6aHDs",
    authDomain: "vollarforgood.firebaseapp.com",
    projectId: "vollarforgood",
    storageBucket: "vollarforgood.appspot.com",
    messagingSenderId: "465785153585",
    appId: "1:465785153585:web:7e285fcb14f605cf994d10",
    measurementId: "G-7NDYM0KVLP"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
if (process.env.NODE_ENV == 'development') connectFunctionsEmulator(functions, "localhost", 5001);


export function getMediaUrl(path) {
    return `https://firebasestorage.googleapis.com/v0/b/vollarforgood.appspot.com/o/${encodeURIComponent(path)}?alt=media`;
}